import type { ITranslationsByLocale } from '~/types/translations';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    lt: {
      joinNow: 'PRISIJUNK DABAR',
      servers: 'Serveriai',
      position: 'Pozicija',
      player: 'Žaidėjas',
      roundsWon: 'Laimėti raundai',
      roundsLost: 'Pralaimėti raundai',
      playtime: 'Pražaidė',
      home: 'Pagrindinis',
      topPlayers: 'Geriausi žaidėjai',
      forum: 'Forumas',
      error: 'Klaida',
      clipboard: 'Iškarpinė',
      serverIpCopied: 'Serverio IP nukopijuotas į iškarpinę',
      user: {
        hello: 'Sveiki',
        personalSpace: 'Asmeninė erdvė',
        vipStatus: 'VIP Būsena',
        activeViolations: 'Aktyvūs taisyklių pažeidimai',
        pastViolations: 'Ankstesni taisyklių pažeidimai',
        activeVIP: 'Jūsų \'{plan}\' privilegijos yra aktyvios iki {untilDate}.',
        noViolations: 'Pažeidimų nėra.',
      },
      suspension: {
        bannedTitle: 'Užbaninti žaidėjai',
        bannedSub: 'Čia gali rasti užbanintų žaidėjų sąrašą. Gali rasti save ar savo draugus, kurie buvo užbaninti, priežastį, trukmę ir dabartinę būseną. Tikimės, kad šiame sąraše savęs ieškoti neprireiks :)',
        playersTotal: 'Iš viso {players} žaidėjai',
        duration: 'Trukmė',
        reason: 'Priežastis',
      },
      status: {
        active: 'Aktyvus',
        inactive: 'Neaktyvus',
        expired: 'Pasibaigė',
        unbanned: 'Atbanintas',
        unmuted: 'Unmuted',
      },
      vip: {
        title: 'VIP Privilegijos',
        subtitle: 'Įsigykite VIP, norėdami gauti tokias funkcijas kaip bunny hop\'ą ar double jump mūsų serveryje. Galite pasirinkti vieną iš trijų lygių, privilegijos galioja 30 dienų po įsigijimo.',
        acceries: 'Papildomi HP ir $$$',
        acceriesDescription: 'Raundo pradžioje gausi $1200, 100 šarvų ir 110 HP.',
        bhop: 'Bhop',
        bhopDescription: 'Bunny hop.',
        bombInfo: 'Bombos informacija',
        bombInfoDescription: 'Kai bomba bus padėta, matysi bombos vietą ir laiką, likusį iki sprogimo.',
        doubleJump: 'Dvigubas šuolis',
        doubleJumpDescription: 'Papildomas šuolis.',
        flash: 'No flash',
        flashDescription: 'Kai aplink nėra priešų, esi atsparus savo ir komandos narių flash\'ams.',
        hp: 'HP už kill\'ą',
        hpDescription: 'Gauk 10 HP už kill\'ą.',
        pack: 'Ginklų ir įrangos paketas',
        packDescription: 'Parašes !pack pasirink įrangos paketą, kur gausi ginklą, granatas ir health shot\'ą.',
        slot: 'Rezervuotas slot\'as',
        slotDescription: 'Rezervuotas žaidėjo slot\'as.',
        smoke: 'Spalvotos dūminės granatos',
        smokeDescription: 'Spalvotos dūminės granatos.',
        tag: 'VIP name tag\'as',
        tagDescription: 'VIP tag\'as rezultatų lentelėje, kill feed\'e ir chat\'e.',
        weapons: 'Ginklų paketas',
        weaponsDescription: 'Parašes !weapons gali pasirinkti ginklų paketą.',
        admin: 'Admin komandos',
        adminDescription: 'Prieiga prie administratoriaus komandų: ban, kick, slay, changemap.',
        everythingFrom: 'Visos privilegijos iš ',
        purchase: 'PIRKTI',
        month: 'mėnesį',
        loginFirst: 'Norėdami įsigyti VIP, turite būti prisijungę',
      },
    },
    en: {
      joinNow: 'JOIN NOW',
      servers: 'Servers',
      position: 'Position',
      player: 'Player',
      roundsWon: 'Rounds won',
      roundsLost: 'Rounds lost',
      playtime: 'Playtime',
      home: 'Home',
      topPlayers: 'Top players',
      forum: 'Forum',
      error: 'Error',
      clipboard: 'Clipboard',
      serverIpCopied: 'Server IP copied to clipboard',
      user: {
        hello: 'Hello',
        personalSpace: 'Personal space',
        vipStatus: 'VIP Status',
        activeViolations: 'Active violations of rules',
        pastViolations: 'Past violations of rules',
        activeVIP: 'Your \'{plan}\' privileges are active until {untilDate}.',
        noViolations: 'No violations',
      },
      suspension: {
        bannedTitle: 'Banned players',
        bannedSub: 'Here you can browse the list of banned players. You can find yourself or your friends who were banned, the reason behind it, duration and the current status. Hopefully you won\'t have to search for yourself in this list :)',
        playersTotal: '{players} players total',
        duration: 'Duration',
        reason: 'Reason',
      },
      status: {
        active: 'Active',
        inactive: 'Inactive',
        expired: 'Expired',
        unbanned: 'Unbanned',
        unmuted: 'Unmuted',
      },
      vip: {
        title: 'VIP Privileges',
        subtitle: 'Purchase VIP to get cool features like bunny hop or double jump on our server. You can choose one of three tiers, privileges are active for 30 days after purchase.',
        acceries: 'Bonus HP and money',
        acceriesDescription: 'Start the round with at least $1200, 100 armour and 110 HP.',
        bhop: 'Bhop',
        bhopDescription: 'Bunny hop.',
        bombInfo: 'Afterplant bomb info',
        bombInfoDescription: 'After the bomb is planted, you can see the bomb site and time left until explosion.',
        doubleJump: 'Double jump',
        doubleJumpDescription: 'After jumping, you have an extra jump.',
        flash: 'No flash',
        flashDescription: 'You are immune to your own and teammate flashes when there are no enemies around.',
        hp: 'HP for a kill',
        hpDescription: 'Get 10 HP for each kill.',
        pack: 'Weapon and equipment pack',
        packDescription: 'Use !pack to select an equipment pack that includes weapon, nades and a health shot.',
        slot: 'Reserved slot',
        slotDescription: 'Reserved 1 player slot.',
        smoke: 'Coloured smokes',
        smokeDescription: 'Coloured smokes.',
        tag: 'VIP name tag',
        tagDescription: 'VIP tag will be shown on the scoreboard, kill feed and chat.',
        weapons: 'Weapon pack',
        weaponsDescription: 'Use !weapons to select a weapon pack which will be given to you each round.',
        admin: 'Admin commands',
        adminDescription: 'Access to admin only commands: ban, kick, slay, changemap.',
        everythingFrom: 'Everything from',
        purchase: 'PURCHASE',
        month: 'month',
        loginFirst: 'You must be logged in to buy VIP',
      },
    },
  } as ITranslationsByLocale,
}));
